import { unmask } from 'remask';
import {
  SignupPayloadProps,
  SigninPayloadProps,
  KbaRegisterPayloadProps,
  MFASigninPayloadProps,
} from './types';

export const transformFindCustomer = (data: string) => ({
  userDocument: unmask(data),
});

export const transformSingupPayload = (
  data: SignupPayloadProps,
  additional: Record<string, string>
) => ({
  cadastroImplicito: true,
  celular: data.cellphone ? unmask(data.cellphone) : '',
  dataNascimento: data.birthdate,
  documento: unmask(data.document),
  email: data.email,
  password: data.password,
  sexo: data.gender,
  nome: data.name,
  termsOfUse: true,
  termsOfUseVersion: 0,
  newsletter: data.newsletter ? data.newsletter : false,
  parameters: {
    answer: data.parameters?.answer,
    captcha: data.captcha,
    kbaSelectedAnswerNumber: data.parameters?.kbaSelectedAnswerNumber,
    "g-recaptcha-response": data.parameters?.["g-recaptcha-response"],
    uuid: data.parameters?.uuid,
    ...additional,
  },
});

export const transformSigninPayload = (data: SigninPayloadProps) => ({
  captcha: null,
  document: data.document,
  password: data.password,
  username: data.document,
});

export const transformMFASigninPayload = (data: MFASigninPayloadProps) => ({
  captcha: null,
  document: data.document,
  password: data.password,
  username: data.document,
  code: data?.verificationCode,
  answers: data?.answers,
  token: data?.token,
  type: data.type,
});

export const transformKbaRegisterPayload = (data: KbaRegisterPayloadProps) => ({
  customerId: unmask(data.document),
  uuid: data.uuid,
});
