// hooks
import { usePushWebSettingsStore } from '../../storage/settings';

export const useWidgetBellHook = () => {
  const { settings: configurationJaiminho } = usePushWebSettingsStore();

  return {
    isVisible:
      !configurationJaiminho.options.bell || configurationJaiminho.isSubscribed
        ? false
        : true,
    margin: configurationJaiminho.options.margin,
    backgroundImage: configurationJaiminho.options.bellBackgroundImage,
  };
};
