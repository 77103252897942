import { useContext } from 'react';
import { SHContext } from '../context/';

export function useSH() {
  const trackSH = useContext(SHContext);

  return {
    ...trackSH,
  };
}

