import React, { ReactNode, createContext, useState } from 'react';

import { getAnonymousID, getSessionID } from 'redline-client-sdk';
import { delay } from '../../../../utils/helpers/delay';
import { isAndroid, isIOS } from '../../../../utils/helpers/mobileOS';
import { getDDD } from '../../../../utils/helpers/getDDD';
import { base64Encrypt } from '../../../../utils/helpers/base64';
import { getRDG } from '../helpers/getRDG';
import { getFbAvailableCookies } from '../helpers/getFacebookPixel';
import { getGAAvailableCookies } from '../helpers/getGoogleAnalyticsCookies';
import { getCookie } from '../helpers/getCookies';

import {
  SHContextType,
  DecisionParams,
  TrackParams,
  SHDecision,
  SHReqProps,
  SafeUser
} from '../types';

import useApiSortingHat from '../hooks/useApiSortingHat';

const SHContext = createContext<SHContextType>({} as SHContextType);

interface SHProvider {
  children: ReactNode;
  mode: 'dev' | 'prod';
  appName: string;
  shKey?: string;
  baseURL?: string;
  maxCallNumber?: number;
}

const SHProvider: React.FC<SHProvider> = ({
  children,
  appName,
  mode,
  shKey,
  baseURL,
  maxCallNumber,
}) => {
  const { SHTrack, SHDecide } = useApiSortingHat({ baseURL, shKey });
  const numberOfCalls = maxCallNumber || 2;

  const [trackDone, setTrackDone] = useState(false);
  const [decideDone, setDecideDone] = useState(false)
  const [trackCount, setTrackCount] = useState(0);
  const [decideCount, setDecideCount] = useState(0);
  const [SHLoading, setSHLoading] = useState(true);
  const [decisionsList, setDecisionsList] = useState<SHDecision[]>([]);

  const isAC = appName === 'ac'
  const application = appName === 'cp' ? 'newco' : 'ac';
  const device = isAndroid() ? 'android' : isIOS() ? 'ios' : 'web';
  const anonymousId = getAnonymousID();
  const workflow_id = isAC ? 'ac-tree-react' : 'cp-tree'

  const callSHTrack = (params?: TrackParams) => {
    const shouldForceCall = params?.forceCall || false;
    if (trackCount === numberOfCalls && !shouldForceCall) return;

    try {
      const user = params?.user;
      const safeUser = {
        document: user?.documento || user?.document || '',
        phone: user?.celular || user?.phone || '',
        birthdate: user?.dataNascimento || user?.birthdate || '',
        full_name: user?.nome || user?.full_name || '',
        email: user?.email || '',
        uuid: user?.uuid || '',
      };

      const payload = handleTrackPayload(safeUser);
      params?.decisionEnrichedEvent();
      SHTrack(payload);

      setTrackCount(trackCount + 1);
      if (!trackDone) {
        setTrackDone(true);
      }
    } catch (err) {
      console.error('ERROR ON SH TRACK', err);
    }
  };

  const callSHDecision = async (params?: DecisionParams) => {
    const shouldForceCall = params?.forceCall || false;
    if (decideCount === numberOfCalls && !shouldForceCall) return;

    const user = params?.user;
    const safeUser = {
      document: user?.documento || user?.document || '',
      uuid: user?.uuid || '',
    };

    try {
      const payload = handleDecidePayload(safeUser);
      params?.decisionRequestEvent();

      await delay(params?.delayTime || 1000);
      const response = await SHDecide(payload);
      const decisionsResult: any = response?.data?.result?.decisions;
      setDecisionsList(decisionsResult);
      setDecideDone(true);


      if (decisionsResult && decisionsResult.length > 0) {
        setSHLoading(false);
        setDecideCount(decideCount + 1);
      }

      if (decisionsResult) {
        const productListPayload = decisionsResult?.map((result: any) => {
          return {
            productCategory: result.category,
            productId: result.slug,
          };
        });
        params?.decisionOutcomeReceivedEvent(productListPayload);
      }

      return decisionsList;
    } catch (err) {
      console.error('ERROR ON SH DECISION', err);
    }
  };

  function handleTrackPayload(safeUser: SafeUser) {
    const trackPayload: SHReqProps = {
      anonymousId,
      document: safeUser?.document,
      cpf: safeUser?.document,
      mainPhone: safeUser?.phone,
      fullName: safeUser?.full_name,
      email: safeUser?.email,
      device,
      identifier: safeUser?.uuid,
      sessionId: getSessionID(),
      fbCookies: getFbAvailableCookies(),
      gaCookies: getGAAvailableCookies(),
      ddd: getDDD(safeUser?.phone || ''),
      rdg: getRDG(appName, safeUser?.document || ''),
      sourceUrl: getCookie('start_source_url') || window?.location.href,
    };

    const encodedPayload = base64Encrypt(JSON.stringify(trackPayload));
    return { data: encodedPayload };
  }

  function handleDecidePayload(safeUser: any) {
    const decidePayload: SHReqProps = {
      identifier: safeUser?.uuid,
      anonymousId: getAnonymousID(),
      document: safeUser?.document,
      cpf: safeUser?.document,
      device,
      workflow_id
    };

    const encodedPayload = base64Encrypt(JSON.stringify(decidePayload));
    return { data: encodedPayload };
  }

  return (
    <SHContext.Provider
      value={{
        callSHDecision,
        callSHTrack,
        decisionsList,
        trackDone,
        decideDone,
        SHLoading,
      }}
    >
      {children}
    </SHContext.Provider>
  );
};

export { SHProvider, SHContext };
