import { useState } from 'react';

// Services
import { Redline } from 'redline-client-sdk';

// Storage
import { usePushWebSettingsStore } from '../../storage/settings';

// Utils
import { subscribePushWeb } from '../../utils/subscribe';
import { shouldPromptAgain } from '../../utils/shouldPromptAgain';

export const useWidgetPrompt = () => {
  const { settings: configurationJaiminho } = usePushWebSettingsStore();

  const initializedPrompt =
    !configurationJaiminho.options.prompt ||
    configurationJaiminho.isSubscribed ||
    !shouldPromptAgain(configurationJaiminho.options.promptFrequency)
      ? false
      : true;

  const [isOpenPrompt, setIsOpenPrompt] = useState(initializedPrompt);

  const handleClosePrompt = () => setIsOpenPrompt(false);

  const handleSubscribePrompt = async () => {
    subscribePushWeb();
    var newWindow = window as Window &
      typeof globalThis & { cpRedline: Redline };

    if (!newWindow.cpRedline) return;
    await newWindow.cpRedline.track('userTracking.ElementClicked.v1', {
      name: 'web-push',
      location: 'dashboard-meu-cpf',
      elementType: 'button',
    });

    setIsOpenPrompt(false);
  };

  const handleDismissPrompt = () => {
    window.localStorage.setItem('pushPromptDismissedAt', String(Date.now()));
    setIsOpenPrompt(false);
  };

  return {
    isOpenPrompt,
    setIsOpenPrompt,
    handleClosePrompt,
    handleSubscribePrompt,
    handleDismissPrompt,
  };
};
