export const getMarketplaceAuthToken = () => {
  const cookies = document.cookie;
  const cookies_map: { [x: string]: string } = {};

  cookies.split(';').forEach((cookie) => {
    const str_value = cookie.trim();
    const [key, value] = str_value.split('=');
    cookies_map[key] = value;
  });
  return cookies_map['TOKEN'];
};
