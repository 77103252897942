import { getCookie } from '@libs/utils/helpers/getSafeCookies';

const useLogoMagaluExperiment = (debt: any) => {
  const testCookieName = 'dx-logo-magalu';

  const VARIANTS = {
    A: 'a-without-logo-magalu',
    B: 'b-with-logo-magalu',
  };

  const currentResult = getCookie(testCookieName, false);

  const isItau = debt?.partner?.partnerIdentifier === 'itau' || debt?.origem?.includes("Itaú");
  const productIsLuizaCred = debt?.product?.includes("LUIZACRED") || debt?.produto?.includes("LUIZACRED");
  const resultIsWithLogoMagalu = !!(currentResult?.includes(VARIANTS.B));

  const showLogoMagalu = isItau && resultIsWithLogoMagalu && productIsLuizaCred;

  const currentLogoIdentifier = showLogoMagalu ? "luizacred" : debt?.partner?.identifier;

  return { currentLogoIdentifier, showLogoMagalu };
};

export default useLogoMagaluExperiment;
