// Hooks
import { usePushWebSettingsStore } from '../storage/settings';

// Types
import type { CustomWindow } from '../types';

export const unsubscribePushWeb = () => {
  const configurationJaiminho = usePushWebSettingsStore.getState();

  const swRegistration = (window as Window & typeof globalThis & CustomWindow)
    .pwRegistration;

  if (!swRegistration) return;

  swRegistration.pushManager
    .getSubscription()
    .then((subscription) => {
      if (subscription) {
        subscription
          .unsubscribe()
          .then((successful) => {
            if (successful) {
              fetch(
                configurationJaiminho.settings.apiPath + '/push/unsubscribe',
                {
                  method: 'POST',
                  body: JSON.stringify(subscription),
                  headers: {
                    'content-type': 'application/json',
                    'x-api-key': configurationJaiminho.settings.apiKey,
                  },
                }
              )
                .then((response) => {
                  const payload = {
                    isSubscribed: false,
                  };
                  configurationJaiminho.handleSetSettings(payload);
                })
                .catch((err) => {
                  console.error(err);
                })
                .finally(() => {
                  console.log(configurationJaiminho.settings.isSubscribed);
                });
            } else {
              console.error('Unsubscription failed');
            }
          })
          .catch((err) => {
            console.error('Failed to unsubscription the user: ', err);
          });
      } else {
        console.log('Unsubscribed');
      }
    })
    .catch((error) => {
      console.error('Error unsubscribing', error);
    });
};
