// Hooks
import { usePushWebSettingsStore } from '../storage/settings';

// Types
import type {
  BodySubscribeType,
  CustomWindow,
  WelcomeNotificationType,
} from '../types';

// Util
import { unsubscribePushWeb } from './unsubscribe';
import { getApplicationServerKey } from './getApplicationServerKey';
import { sendPushDataToMarketplace } from './sendPushDataToMarketplace';

export const subscribePushWeb = () => {
  const configurationJaiminho = usePushWebSettingsStore;

  getApplicationServerKey()
    .then((appKey) => {
      const swRegistration = (
        window as Window & typeof globalThis & CustomWindow
      ).pwRegistration;

      const appKeyFormatted = appKey as string;

      if (!swRegistration || typeof swRegistration === 'string') return;

      swRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: appKeyFormatted,
        })
        .then((subscription) => {
          // const body: BodySubscribeType = {
          //   origin: window.location.origin,
          //   subscription: subscription,
          //   userId: '',
          //   welcome: {},
          // };

          // Nao vai para lugar nenhum
          // const welcomeNotification: WelcomeNotificationType = {
          //   title: '',
          //   options: {
          //     body: '',
          //     icon: '',
          //     badge: '',
          //   },
          // };

          // if (configurationJaiminho.getState().settings.userId) {
          //   body.userId =
          //     configurationJaiminho.getState().settings.userId || '';
          // }

          // if (configurationJaiminho.getState().settings.options.welcome) {
          //   welcomeNotification.title =
          //     configurationJaiminho.getState().settings.options?.welcomeTitle ||
          //     '';
          //   welcomeNotification.options.body =
          //     configurationJaiminho.getState().settings.options.welcomeBody ||
          //     '';

          //   if (configurationJaiminho.getState().settings.options.welcomeIcon)
          //     welcomeNotification.options.icon =
          //       configurationJaiminho.getState().settings.options.welcomeIcon ||
          //       '';
          //   if (configurationJaiminho.getState().settings.options.welcomeBadge)
          //     welcomeNotification.options.badge =
          //       configurationJaiminho.getState().settings.options
          //         .welcomeBadge || '';
          //   body.welcome = welcomeNotification;
          // }

          new Promise((res: Function) => {
            res();
          })
            .then(() => {
              const payload = {
                isSubscribed: true,
              };

              configurationJaiminho.getState().handleSetSettings(payload);

              window.localStorage.setItem('_hmsls', String(Date.now()));
            })
            .catch((err) => {
              console.error(err);
              unsubscribePushWeb();
            })
            .finally(() => {
              console.log('get application server key finally');
            });

          sendPushDataToMarketplace({
            slug: 'push-web',
            payload: {
              ...subscription.toJSON()['keys'],
              endpoint: subscription.endpoint,
            },
          });
        })
        .catch((err) => {
          console.error('Failed to subscribe the user: ', err);
          unsubscribePushWeb();
        });
    })
    .catch((err) => {
      console.error('Failed to subscribe the user: ', err);
    });
};
