import { useCallback, useEffect, useState } from 'react';

import {
  useSH,
  SHContent,
  SHMetadata,
  SHDecisionGeneralInfo
} from '@libs/sortinghat';
import { delay } from '@libs/utils/helpers/delay';

import { useUserStore } from '@common/store/useUserStore';
import useTrackOffer from '@common/hooks/useTrackOffer';

export type SHDecisionItem = {
  generalInfo: SHDecisionGeneralInfo;
  metadata: Partial<SHMetadata>;
  content: SHContent;
};

type UseSortingHat = {
  immediate?: boolean;
  decideDelayInSeconds?: number;
};

export const useSortingHatLib = ({
  immediate,
  decideDelayInSeconds,
}: UseSortingHat = {}) => {

  const user = useUserStore((state) => state.user);
  const { callSHTrack, callSHDecision, decisionsList, trackDone, decideDone } = useSH();

  const {
    trackDecisionEnriched,
    trackDecisionRequested,
    trackDecisionOutcomeReceived,
  } = useTrackOffer();

  const [shLoading, setShLoading] = useState(true);
  const [shError, setShError] = useState(false);

  useEffect(() => {
    if (immediate) {
      trackDone ? shDecision() : shTrack();
    }

  }, [trackDone, immediate]);

  const shTrack = useCallback(() => {
    try {
      if (user && !trackDone)  {

        callSHTrack({ user, decisionEnrichedEvent: trackDecisionEnriched });
      } else {
        console.warn('[SH_TRACK] User not found');
      }
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  const shDecision = useCallback(async () => {
    try {
      if (trackDone) {
        if (decideDelayInSeconds) {
          // this logic is necessary because, decide default delay is 1 second
          const milliseconds = (decideDelayInSeconds - 1) * 1000;
          if (milliseconds > 0) {
            await delay(milliseconds);
          }
        }

        callSHDecision({
          user,
          decisionRequestEvent: trackDecisionRequested,
          decisionOutcomeReceivedEvent: trackDecisionOutcomeReceived,
        });
      } else {
        console.warn('[SH_DECIDE] Track not done yet');
      }
    } catch (error) {
      console.error(error);
    }
  }, [user, trackDone, decideDelayInSeconds]);

  return {
    shTrack,
    shDecision,
    decisionsList,
    decideDone,
    shError,
    shLoading,
  };
};
