import { usePushWebSettingsStore } from '../storage/settings';

// Utils
import { convertBase64ToUnitInt } from './convertBase64ToUnitInt';

export const getApplicationServerKey = () => {
  const configurationJaiminho = usePushWebSettingsStore;

  return new Promise((resolve, reject) => {
    if (
      configurationJaiminho.getState().settings.applicationServerKey !=
        undefined &&
      !!configurationJaiminho.getState().settings?.applicationServerKey?.length
    ) {
      resolve(configurationJaiminho.getState().settings.applicationServerKey);
    } else {
      fetch(
        configurationJaiminho.getState().settings.apiPath +
          '/push/publicSigningKeyBase64',
        {
          headers: {
            'content-type': 'application/json',
            'x-api-key': configurationJaiminho.getState().settings.apiKey,
          },
        }
      )
        .then((response) => response.text())
        .then((key) => convertBase64ToUnitInt(key))
        .then((appKey) => {
          const payload = {
            applicationServerKey: appKey,
          };
          configurationJaiminho.getState().handleSetSettings(payload);
          resolve(appKey);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};
