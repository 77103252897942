import { getCookie } from '../helpers/getCookies'

export function getGa() {
  return getCookie('_ga');
}

export function getGAAvailableCookies(): { ga?: string} {
  const ga = getGa();
  if (!ga) return {};

  return { ga };
}
