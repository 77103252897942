// hooks
import { usePushWebSettingsStore } from '../storage/settings';

// Utils
import { getMarketplaceAuthToken } from './getMarketplaceAuthToken';

export const sendPushDataToMarketplace = (data: object) => {
  const configurationJaiminho = usePushWebSettingsStore.getState();

  if (configurationJaiminho.settings.marketplaceApi === '') {
    console.error('push web api not defined');
  }

  const authToken = getMarketplaceAuthToken();

  fetch(
    `${configurationJaiminho.settings.marketplaceApi}/marketplace/v1/app/engage/create-entity`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': authToken,
      },
      body: JSON.stringify(data),
    }
  )
    .then((_) => console.log('subscribed to receive push notifications'))
    .catch((error) => {
      console.error('error subscribe to engage push notification', error);
    });
};
