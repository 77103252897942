// Hooks
import { usePushWebSettingsStore } from '../storage/settings';

// Types
import type { CustomWindow } from '../types';

export const setUserId = (userId: string) => {
  const configurationJaiminho = usePushWebSettingsStore;
  const payload = {
    userId,
  };
  const swRegistration = (window as Window & typeof globalThis & CustomWindow)
    .pwRegistration;

  configurationJaiminho.getState().handleSetSettings(payload);

  if (
    !configurationJaiminho.getState().settings.isSubscribed ||
    !swRegistration
  )
    return;

  swRegistration.pushManager
    .getSubscription()
    .then((subscription) => {
      if (subscription) {
        var body = {
          userId,
          subscription,
        };
      }
    })
    .catch((err) => {
      console.error('Error during getSubscription()', err);
    });
};
