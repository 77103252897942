// Components
import { WidgetBell } from './components/WidgetBell';
import { WidgetPrompt } from './components/WidgetPrompt';

// Types
import type { CustomWindow, JaiminhoClientTypes } from './types';

// hooks
import { usePushWebSettingsStore } from './storage/settings';
import { useEffect } from 'react';

// utils
import { isSubscriptionStorage } from './utils/isSubscriptionStorage';
import { subscribePushWeb } from './utils/subscribe';
import { convertBase64ToUnitInt } from './utils/convertBase64ToUnitInt';

export const JaiminhoClient = ({
  apiKey,
  apiPath,
  applicationServerKey,
  marketplaceApi = '',
  options = {},
}: JaiminhoClientTypes) => {
  const { handleSetSettings } = usePushWebSettingsStore();

  // Functions
  const handleGetSubscription = () => {
    Notification.requestPermission(function (status) {
      console.log('Notification permission status:', status);
    });

    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
      console.warn("Notifications aren't supported.");
    }

    if (Notification.permission === 'denied') {
      console.warn('The user has blocked notifications.');
    }

    if (!('PushManager' in window)) {
      console.warn("Push messaging isn't supported.");
    }

    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then((subscription) => {
          handleSetSettings({
            isInitialized: true,
          });

          /// Se existir
          if (subscription) {
            handleSetSettings({
              isSubscribed: true,
            });

            isSubscriptionStorage()
              .then((result) => {
                if (result) return;

                // TODO: Por que eu me desinscrevo para me inscrever?
                subscription
                  .unsubscribe()
                  .then((successful) => {
                    if (successful) {
                      handleSetSettings({
                        isSubscribed: false,
                      });

                      subscribePushWeb();
                    } else {
                      console.error('Unsubscription failed');
                    }
                  })
                  .catch((err) => {
                    console.error('Failed to unsubscription the user: ', err);
                  });
              })
              .catch((err) => {
                console.error('Error during getSubscription()', err);
              });
          }
        })
        .catch((err) => {
          console.error('Error during get subscription', err);
        });
    });
  };

  /// Start e instalo o SW
  const startServiceWoker = () => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then((swRegistration) => {
            (
              window as Window & typeof globalThis & CustomWindow
            ).pwRegistration = swRegistration;

            handleSetSettings(options);
            handleGetSubscription();

            console.log(
              `ServiceWorker registration successful with scope: ${swRegistration.scope}`
            );
            return swRegistration.update();
          })
          .then((registration) => console.log(`ServiceWorker updated!!!!!`))
          .catch((err) =>
            console.log('ServiceWorker registration failed: ', err)
          );
      });
    }
  };

  useEffect(() => {
    if (applicationServerKey != undefined && applicationServerKey.length > 0) {
      const serverKey = convertBase64ToUnitInt(applicationServerKey);

      handleSetSettings({
        applicationServerKey: serverKey,
        apiKey,
        apiPath,
        marketplaceApi,
      });
    }

    startServiceWoker();
  }, []);

  return (
    <>
      <WidgetBell position="left" />
      <WidgetPrompt />
    </>
  );
};
