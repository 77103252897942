import { useDrawer } from '@consumidor-positivo/aurora';
import { getPartnerImage } from '@libs/utils/helpers/getPartnerImage';
import { useParams } from 'react-router-dom';
import { usePrivatePagesHandlers } from './useHandlers';
import { usePrivatePagesStates } from './useStates';
import { useUserStore } from '@common/store/useUserStore';
import { ARC4U_IDENTIFIER } from '@common/constants/hard-coded-partners';
import useLogoMagaluExperiment from '@common/abtest/experiments/useLogoMagaluExperiment';

import { useSortingHatLib } from '@common/hooks/useSortingHatLib';

export const usePrivatePageHook = () => {
  const [myDebtStore] = useUserStore(state => [state.myDebt]);
  const params = useParams();
  const debtProps = myDebtStore?.propriedades || [];
  const isArc = debtProps.some((prop: any) => prop?.chave === 'ARC_DEBT');
  const partnerIdentifier = params?.partner || myDebtStore?.partner?.identifier;

  useSortingHatLib({immediate: true})

  //#region A/B TEST LOGO MAGALU
  const { currentLogoIdentifier } = useLogoMagaluExperiment(myDebtStore);
  const currentPartnerLogo = currentLogoIdentifier || partnerIdentifier;
  //#endregion A/B TEST LOGO MAGALU

  const partnerImage = getPartnerImage(isArc ? ARC4U_IDENTIFIER : currentPartnerLogo);

  const {
    dataSourceNavbar,
    dataSourceNavbarVertical,
    showLogoutModal,
    setShowLogoutModal,
  } = usePrivatePagesStates();
  const {
    onClickBackMenu,
    onClickLogout,
    onClickNotLogout,
    onClickLogoutModal,
  } = usePrivatePagesHandlers({ setShowLogoutModal });
  const { drawerOpen, handleOpenDrawer } = useDrawer({
    menu: false,
    notification: false,
  });

  return {
    dataSourceNavbar,
    dataSourceNavbarVertical,
    drawerOpen,
    handleOpenDrawer,
    onClickBackMenu,
    onClickLogout,
    onClickNotLogout,
    onClickLogoutModal,
    partnerImage,
    showLogoutModal,
  };
};
